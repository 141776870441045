IPAPI_ACCESS_KEY = 'eb18ff4ff6fe425ea27a027e6df48881'
geoXhr = new XMLHttpRequest()
geoUrl = "https://api.ipgeolocation.io/ipgeo?apiKey=" + IPAPI_ACCESS_KEY
serverXhr = new XMLHttpRequest()
serverUrl = "/rest/statistics"

geoXhr.onloadend = ->
	if geoXhr.status is 200
		geoData = JSON.parse(geoXhr.response)
		data = {
			ip: geoData.ip,
			country: geoData.country_name || "Pays inconnu",
			city: geoData.city || "Ville inconnu",
			latitude: if geoData.latitude then parseFloat(geoData.latitude) else 0,
			longitude: if geoData.longitude then parseFloat(geoData.longitude) else 0
		}

		rawData = JSON.stringify(data)
		serverXhr.open("POST",serverUrl)
		serverXhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
		serverXhr.send(rawData)

geoXhr.open("GET",geoUrl)
geoXhr.send()